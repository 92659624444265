.wrapper-story {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 900px) {
    min-height: 100vh;
    height: auto;
  }

  .story-of-mocas-section {
    width: 100vw;
    min-height: 100vh;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90px 0;
    box-sizing: border-box;

    @media only screen and (max-width: 900px) {
      padding: 150px 0 0;
      box-sizing: border-box;
      min-height: 0px;
      height: auto;
      justify-content: flex-start;
    }

    &.bg-clouds {
      background-image: url(../../images/bgclouds1.svg);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;

      @media only screen and (max-width: 900px) {
        background-image: none;
      }
    }

    &.bg-clouds2 {
      background-image: url(../../images/bgclouds2.svg);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;

      @media only screen and (max-width: 900px) {
        background-image: none;
      }
    }

    &.bg-clouds3 {
      background-image: url(../../images/bgclouds3.svg);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;

      @media only screen and (max-width: 900px) {
        background-image: none;
      }
    }

    .orbit-rings {
      background-image: url(../../images/orbit-rings.svg);
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      z-index: -1;

      @media only screen and (max-width: 900px) {
        background-image: none;
      }
    }

    .objects {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      background-image: url(../../images/bg-floating-objects.svg);
      background-position: center center;
      background-size: 75%;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;

      @media only screen and (max-width: 900px) {
        display: none;
      }
    }
  }

  .story-intro-wrapper {
    text-align: center;
    position: relative;
    z-index: 1;
    position: relative;
    display: block;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    min-height: 800px;

    @media only screen and (max-width: 1440px) {
      min-height: 670px;
    }
  }

  .story-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 100%;
    margin: 0 auto;
    position: relative;
    left: 0;
    right: 0;
    top: 0;

    .story-logo {
      bottom: -30px;
      position: relative;
      display: block;
      margin: 0 auto;
      z-index: 1;
    }
  }

  .story-content-intro {
    background-image: url(../../images/storyVector.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 538px;
    height: 548px;
    z-index: 1;
    position: relative;
    margin: 0 auto;

    @media only screen and (max-width: 1440px) {
      height: 400px;
    }

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .content {
      position: absolute;
      left: 0;
      right: 0;
      top: 25%;
      width: 250px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      display: block;
      margin: 0 auto;
      text-align: left;

      @media only screen and (max-width: 1440px) {
        width: 210px;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  .story-content-intro-mobile {
    display: none;
    background-image: url(../../images/what-is-mocaverse-mobile.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    position: relative;
    width: 100%;
    height: 537px;
    left: 0;
    right: 0;
    margin: 0 auto;

    @media only screen and (max-width: 900px) {
      display: block;
    }

    .content {
      position: absolute;
      left: 0;
      right: 0;
      width: 250px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #ffffff;
      display: block;
      margin: 0 auto;
      text-align: left;
      top: 160px;
    }
  }

  .story-blob-wrapper {
    text-align: center;
    position: relative;
    z-index: 1;
    position: relative;
    display: block;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    min-height: 660px;

    @media only screen and (max-width: 1440px) {
      min-height: 530px;
      max-width: 1000px;
    }

    img {
      max-width: 613px;
      width: 100%;
      top: 100px;
      position: relative;
      @media only screen and (max-width: 1440px) {
        max-width: 400px;
        top: 120px;
      }

      @media only screen and (max-width: 900px) {
        max-width: 300px;
        top: 0;
        position: relative;
      }
    }

    .story-blob-1 {
      background-image: url(../../images/story-blob-1.svg);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 419px;
      height: 190px;
      top: 0;
      left: 0;

      @media only screen and (max-width: 1440px) {
        width: 356px;
      }

      @media only screen and (max-width: 900px) {
        display: none;
      }

      .content {
        padding-top: 47px;
        padding-left: 41px;
        padding-right: 64px;

        @media only screen and (max-width: 1440px) {
          padding-top: 31px;
          padding-left: 30px;
          padding-right: 46px;
        }
      }
    }

    .story-blob-2 {
      background-image: url(../../images/story-blob-2.svg);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 380px;
      height: 172px;
      right: 0;
      top: 25%;

      @media only screen and (max-width: 1440px) {
        width: 323px;
      }

      @media only screen and (max-width: 900px) {
        display: none;
      }

      .content {
        padding-top: 40px;
        padding-left: 31px;
        padding-right: 45px;

        @media only screen and (max-width: 1440px) {
          padding-top: 30px;
          padding-left: 27px;
          padding-right: 49px;
        }
      }
    }

    .story-blob-3 {
      background-image: url(../../images/story-blob-3.svg);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 431px;
      height: 244px;
      bottom: -70px;
      left: 5%;

      @media only screen and (max-width: 1440px) {
        width: 366px;
        bottom: -67px;
      }

      @media only screen and (max-width: 900px) {
        display: none;
      }

      .content {
        padding-top: 50px;
        padding-left: 38px;
        padding-right: 59px;

        @media only screen and (max-width: 1440px) {
          padding-top: 39px;
          padding-left: 34px;
          padding-right: 57px;
        }
      }
    }

    .story-blob-mobile {
      display: none;
      background-image: url(../../images/story-blob-mobile.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      position: relative;
      width: 100%;
      height: 479px;
      left: 0;
      right: 0;
      margin: 0 auto;

      @media only screen and (max-width: 900px) {
        display: block;
        padding: 0 50px;
        box-sizing: border-box;
      }

      .content {
        padding-top: 110px;
        max-width: 250px;
        margin: 0 auto;
        overflow-wrap: break-word;
      }
    }

    .content {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      @media only screen and (max-width: 1440px) {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  .story-content {
    position: relative;
    @media only screen and (max-width: 900px) {
      padding: 0 20px;
      box-sizing: border-box;
    }
    .cloud {
      position: absolute;
      left: -120px;
      top: -30px;
      z-index: 0;
    }

    .rocket {
      position: absolute;
      right: -20px;
      bottom: -50px;
      z-index: 1;

      @media only screen and (max-width: 900px) {
        right: 2px;
      }
    }

    .decorations {
      position: absolute;
      right: -20px;
      top: -50px;
      z-index: 1;

      @media only screen and (max-width: 900px) {
        right: 2px;
      }
    }

    .pinkbg {
      padding: 20px 40px;
      border: 3px solid black;
      background-color: #ffafff;
      border-radius: 60px 0px 60px 0px;
      height: auto;

      @media only screen and (max-width: 900px) {
        border-radius: 40px 0px 40px 0px;
      }
    }

    .yellowboard {
      padding: 0.5em;
      border: 3px solid black;
      background-color: #ffde00;
      box-shadow: 0px 10px 0px black;
      border-radius: 70px 0px 70px 0px;
      position: relative;
      right: 20px;

      @media only screen and (max-width: 900px) {
        border-radius: 50px 0px 50px 0px;
        right: 0;
      }
    }

    .realm-text {
      max-width: 726px;
      width: 100%;
      top: 132px;
      left: 73px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #000000;

      @media only screen and (max-width: 900px) {
        font-size: 12px;
        line-height: 20px;
      }

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .themocas {
    width: 100%;
    margin: 50px auto 0;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    max-width: 1600px;

    @media only screen and (max-width: 1440px) {
      margin: 20px auto 0;
      padding: 0 60px;
    }

    @media only screen and (max-width: 900px) {
      padding: 0;
    }

    &.desktop {
      @media only screen and (max-width: 900px) {
        display: none;
      }
    }

    &.slider {
      display: none;
      @media only screen and (max-width: 900px) {
        display: block;
        margin: 60px auto 0;
      }
      .slick-arrow.slick-prev {
        left: 20px;
        z-index: 1000;
      }

      .slick-arrow.slick-next {
        right: 20px;
        z-index: 1000;
      }
    }

    .moca {
      &__1 {
        width: 20%;
        padding: 0 20px;
        box-sizing: border-box;

        @media only screen and (max-width: 1440px) {
          padding: 0 10px;
        }

        @media only screen and (max-width: 900px) {
          width: 100%;

          .moca-icons {
            margin-left: 40px;
          }
        }
      }
      &__3 {
        width: 20%;
        padding: 0 20px;
        box-sizing: border-box;

        @media only screen and (max-width: 1440px) {
          padding: 0 10px;
        }

        @media only screen and (max-width: 900px) {
          width: 100%;
        }
      }
      &__5 {
        width: 20%;
        padding: 0 20px;
        box-sizing: border-box;

        @media only screen and (max-width: 1440px) {
          padding: 0 10px;
        }

        @media only screen and (max-width: 900px) {
          width: 100%;

          .moca-icons {
            margin-left: 55px;
          }
        }
      }
      &__2 {
        margin-top: 50px;
        width: 20%;
        padding: 0 20px;
        box-sizing: border-box;

        @media only screen and (max-width: 1440px) {
          padding: 0 10px;
        }

        @media only screen and (max-width: 900px) {
          width: 100%;
          margin-top: 0px;

          .moca-icons {
            margin-left: 50px;
          }
        }
      }
      &__4 {
        margin-top: 50px;
        width: 20%;
        padding: 0 20px;
        box-sizing: border-box;

        @media only screen and (max-width: 1440px) {
          padding: 0 10px;
        }

        @media only screen and (max-width: 900px) {
          width: 100%;
          margin-top: 0px;
        }
      }
    }

    .moca-icons {
      width: 100%;
      height: 220px;

      @media only screen and (max-width: 1440px) {
        width: 70%;
        height: 170px;
        display: block;
        margin: 0 auto;
      }
    }

    .moca-text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 51px;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;

      &:after {
        display: block;
        top: 0;
        left: 0;
        -webkit-font-smoothing: antialiased;
        width: 100%;
        height: 100%;
        position: absolute;
        text-shadow: 0px 0px 20px #6be4ff,
          0px 4px 15px rgba(109, 229, 255, 0.23), 0px 0px 2px #ffffff,
          0px 0px 13px rgba(0, 102, 255, 0.5);
      }

      &.dreamers {
        color: #73f96e;
        &:after {
          content: 'DREAMERS';
        }
      }

      &.builders {
        color: #e1e1e1;
        &:after {
          content: 'BUILDERS';
        }
      }

      &.angels {
        color: #179eff;
        &:after {
          content: 'ANGELS';
        }
      }

      &.connectors {
        color: #fffaaa;
        &:after {
          content: 'CONNECTORS';
        }
      }

      &.neocapitalist {
        color: #ff7a6f;
        &:after {
          content: 'NEO-CAPITALISTS';
        }
      }
    }

    .moca-info {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;

      @media only screen and (max-width: 1440px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.story-tribe {
  width: 100%;
  max-width: 1600px;
  margin: 40px 0 0;

  @media only screen and (max-width: 560px) {
    margin: 20px 0 0;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  &-item {
    margin: 0 0 15px;
    padding: 0 15px;
    width: 20%;

    @media only screen and (max-width: 950px) {
      width: 33.33%;
    }

    @media only screen and (max-width: 560px) {
      width: 50%;
    }
  }

  &-box {
    text-align: center;
  }

  &-image {
    margin: 0 0 6px;

    img {
      max-width: 100%;
    }
  }

  &-subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);
    margin: 0;
  }

  &-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    @media only screen and (max-width: 900px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &-socials {
    a {
      @extend .social-animation;
    }
  }
}

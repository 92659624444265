.realms-section {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 0;
  box-sizing: border-box;

  &.orbit-rings-no-outer-rings {
    background-image: url(../../images/no-outer-ring.svg);
    background-position: center center;
    background-size: 90%;
    background-repeat: no-repeat;

    @media only screen and (max-width: 900px) {
      background-image: none;
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 150px 0 0;
    box-sizing: border-box;
    min-height: 0;
    height: auto;
  }

  .realm {
    &__row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      max-width: 1300px;
      width: 100%;
      margin: 120px auto 0;

      @media only screen and (max-width: 1440px) {
        margin: 100px auto 0;
        padding: 0 60px;
        height: 400px;
        box-sizing: border-box;
      }

      @media only screen and (max-width: 900px) {
        display: none;
      }
    }

    &__block-info {
      background-image: url(../../images/realminfoblob.svg);
      background-position: center center;
      background-size: 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      padding: 10px 48px;
      box-sizing: border-box;
      height: 55px;

      @media only screen and (max-width: 900px) {
        display: none;
      }
    }

    &__learn,
    &__build,
    &__play,
    &__dogood {
      width: 25%;

      .text {
        width: 50%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        &.learn {
          top: -25px;
        }

        &.build {
          top: -48px;
          left: -27px;
        }

        &.play {
          top: -25px;
        }

        &.dogood {
          top: -10px;
          width: 200px;
        }
      }

      .realm {
        width: 100%;
      }
    }

    &__learn,
    &__play {
      margin-top: 150px;
    }

    &__build {
      margin-top: 100px;
    }

    &__dogood {
      .realm__content {
        &:hover {
          .realm__info {
            top: -152px;

            @media only screen and (max-width: 1440px) {
              top: -115px;
            }
          }
        }
        .realm__info {
          top: -132px;

          @media only screen and (max-width: 1440px) {
            top: -95px;
          }
        }
      }
    }

    &__content {
      position: relative;

      &:hover {
        .realm__info {
          top: -170px;
          visibility: visible;

          @media only screen and (max-width: 1440px) {
            top: -134px;
          }
        }
      }
    }

    &__info {
      height: 180px;
      display: block;
      position: absolute;
      width: 280px;
      height: auto;
      background-color: #ffafff;
      border-bottom-right-radius: 30px;
      box-shadow: 0px 15px 0px black;
      top: -150px;
      padding: 10px;
      box-sizing: border-box;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      z-index: 1;
      visibility: hidden;
      transition: top 0.6s ease-in-out, visibility 0.5s ease-in-out;

      @media only screen and (max-width: 1440px) {
        top: -114px;
      }

      &:after {
        content: '▼';
        position: absolute;
        color: #ffafff;
        bottom: -17px;
        left: 50%;
        font-size: 20px;
      }

      @media only screen and (max-width: 1440px) {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  .realms-slider {
    display: none;
    @media only screen and (max-width: 900px) {
      display: block;
      width: 100%;
    }

    .realms-slider-item {
      padding: 30px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 520px;
    }

    .slick-arrow.slick-prev {
      left: 20px;
      z-index: 1000;
      top: 180px;
    }

    .slick-arrow.slick-next {
      right: 20px;
      z-index: 1000;
      top: 180px;
    }

    .realm {
      height: 320px;
      width: auto;
    }

    .realm-info {
      position: relative;
      background-color: #ffafff;
      width: 100%;
      height: auto;
      border-bottom-right-radius: 30px;
      box-shadow: 0px 15px 0px black;
      margin: 30px auto;
      padding: 18px 10px;
      box-sizing: border-box;
      max-width: 380px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;

      .text {
        width: 50%;
        position: absolute;

        &.play {
          top: -83px;
          left: -10px;
        }

        &.learn {
          top: -80px;
          left: -10px;
          transform: rotate(17deg);
        }

        &.invest {
          top: -98px;
          left: -10px;
        }

        &.dogood {
          left: -10px;
          transform: rotate(11deg);
          top: -96px;
          width: 68%;
        }
      }
    }
  }
}

.packing {
  background-image: url(../../images/Packing.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 54px 30px 50px;
  box-sizing: border-box;

  @media only screen and (max-width: 900px) {
    padding: 77px 15px 50px;
  }

  &-message {
    background: #fff;
    border: 2px solid #000;
    border-radius: 100px;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    padding: 10px 58px 10px 40px;
    color: #0a0a0a;
    margin: 0 0 25px;

    @media only screen and (max-width: 900px) {
      border-radius: 20px;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 20px;
    }

    strong {
      color: #ff6e00;
      font-weight: 700;
    }
  }

  &-section {
    position: relative;
    max-width: 900px;
    min-height: 780px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media only screen and (max-width: 900px) {
      height: calc(100vh - 237px);
      min-height: 668px;
    }
  }

  &-grid {
    width: 100%;
    height: 536px;
    position: absolute;
    top: 0;

    @media only screen and (max-width: 900px) {
      max-width: 310px;
      height: auto;
      margin-bottom: -5px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    img {
      width: 86px;

      @media only screen and (max-width: 900px) {
        width: 65%;
      }
    }
  }

  &-grid-item {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    transition: opacity 0.5s;
    position: absolute;

    @media only screen and (max-width: 900px) {
      position: static;
      width: 25%;
      min-height: 82px;
    }

    &.added {
      opacity: 0.6;
    }

    &.book {
      top: 47px;
      left: 67px;
    }

    &.hammer {
      top: 172px;
      left: 703px;

      img {
        width: 95px;
        @media only screen and (max-width: 900px) {
          width: 71%;
        }
      }
    }

    &.cube {
      bottom: 20px;
      left: 30px;

      img {
        width: 102px;
        @media only screen and (max-width: 900px) {
          width: 76%;
        }
      }
    }

    &.skateboard {
      top: 155px;
      left: 527px;
      img {
        width: 122px;
        @media only screen and (max-width: 900px) {
          width: 90%;
        }
      }
    }

    &.binoculars {
      top: 291px;
      left: 257px;
      img {
        width: 90px;
        @media only screen and (max-width: 900px) {
          width: 68%;
        }
      }
    }

    &.ape {
      bottom: 21px;
      left: 645px;

      img {
        width: 88px;
        @media only screen and (max-width: 900px) {
          width: 66%;
        }
      }
    }

    &.banana {
      top: 285px;
      left: 637px;
      img {
        width: 95px;
        @media only screen and (max-width: 900px) {
          width: 71%;
        }
      }
    }

    &.benji {
      top: 21px;
      left: 576px;
      img {
        width: 104px;
        @media only screen and (max-width: 900px) {
          width: 79%;
        }
      }
    }

    &.boot {
      top: 312px;
      left: 113px;
      img {
        width: 90px;
        @media only screen and (max-width: 900px) {
          width: 68%;
        }
      }
    }

    &.car {
      top: 150px;
      left: 334px;

      img {
        width: 132px;
        @media only screen and (max-width: 900px) {
          width: 98%;
        }
      }
    }

    &.cat {
      top: 264px;
      left: 439px;
    }

    &.coldstorage {
      top: 0;
      left: 401px;

      img {
        @media only screen and (max-width: 900px) {
          width: 72%;
        }
      }
    }

    &.hopium {
      top: 170px;
      left: -6px;

      img {
        width: 94px;
        @media only screen and (max-width: 900px) {
          width: 70%;
        }
      }
    }

    &.nothing {
      top: 21px;
      left: 233px;

      img {
        width: 82px;

        @media only screen and (max-width: 900px) {
          width: 60%;
        }
      }
    }

    &.sword {
      top: 161px;
      left: 184px;
      img {
        width: 100px;
        @media only screen and (max-width: 900px) {
          width: 75%;
        }
      }
    }
  }

  &-case {
    position: absolute;
    bottom: -4px;
    z-index: 1;

    @media only screen and (max-width: 900px) {
      position: relative;
    }

    > img {
      max-width: 401px;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;

      @media only screen and (max-width: 900px) {
        max-width: 259px;
      }
    }

    .btn-img {
      width: 34px;
      height: 34px;
      position: absolute;
      right: -15px;
      top: -15px;
      padding: 0;
      background: none;
      border: 0;
      margin: 0;
      z-index: 2;

      @media only screen and (max-width: 900px) {
        width: 21px;
        height: 21px;
        top: -8px;
        right: -8px;
      }

      img {
        width: 100%;
      }
    }

    .dropable {
      width: 72px;
      height: 71px;
      display: block;
      position: absolute;
      z-index: 1;

      @media only screen and (max-width: 900px) {
        width: 46px;
        height: 46px;
      }

      &.empty {
        background: url(../../images/packing/case-box.svg) center center
          no-repeat;
        background-size: contain;
      }

      > img {
        display: block;
        position: absolute;
        top: revert;
        left: 0;
        width: 72px;
        height: 71px;

        @media only screen and (max-width: 900px) {
          width: 46px;
          height: 46px;
        }
      }
    }

    .dropzone-1 {
      top: 236px;
      left: 88px;

      @media only screen and (max-width: 900px) {
        top: 151px;
        left: 58px;
      }
    }

    .dropzone-2 {
      top: 207px;
      left: 171px;

      @media only screen and (max-width: 900px) {
        top: 133px;
        left: 111px;
      }
    }

    .dropzone-3 {
      top: 237px;
      right: 76px;

      @media only screen and (max-width: 900px) {
        top: 152px;
        right: 49px;
      }
    }
  }

  &-action {
    position: absolute;
    height: 220px;
    width: calc(100% - 15px);
    bottom: 312px;
    left: 255px;
    max-width: 812px;

    @media only screen and (max-width: 1280px) {
      left: 27%;
    }

    @media only screen and (max-width: 900px) {
      height: auto;
      width: calc(100% + 30px);
      left: 0;
      background: #75c6f7;
      position: fixed;
      bottom: 0;
      z-index: 1;
    }

    &-wrapper {
      width: 100%;
      padding: 24px 0 0 152px;
      position: relative;
      z-index: 1;

      @media only screen and (max-width: 1250px) {
        width: 80%;
      }

      @media only screen and (max-width: 900px) {
        width: 100%;
        padding: 13px 10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-img {
      position: absolute;
      width: 158px;
      height: auto;
      top: 0;
      left: 0;
      z-index: 0;

      @media only screen and (max-width: 900px) {
        display: none;
      }

      img {
        width: 100%;
      }
    }

    &-text {
      background: #ffffff;
      border: 2px solid #000000;
      border-radius: 30px;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      padding: 8px 16px;
      margin: 0 0 5px;
      @media only screen and (max-width: 900px) {
        display: none;
      }
    }

    &-btn.btn {
      height: 52px;

      &:before {
        margin-right: 10px;
      }
    }
  }
}

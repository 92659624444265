.moca {
  background-image: url(../../images/Moca-Instructions.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  height: auto;
}

.loadingscreen {
  background-image: url(../../images/wormhole-dark-space.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  .wormhole {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .portal {
      width: 842px;
    }

    .fueling {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #f8d748;
      position: absolute;
      bottom: 181px;
    }

    .loadingbar {
      position: relative;
      bottom: 260px;
      @media only screen and (max-width: 700px) {
        width: 80%;
        bottom: 95px;
      }
    }

    .loading-bar-block {
      max-width: 415px;
      width: 100%;
      height: 73px;
      background: #2b4c91;
      border: 3px solid #000000;
      border-radius: 30px;
      flex-direction: row;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5px;
      box-sizing: border-box;
      position: absolute;
      bottom: 100px;

      .percent {
        position: absolute;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        color: #f8d748;
        left: 0;
        right: 0;
        text-align: center;
        text-shadow: rgb(0, 0, 0) 3px 0px 0px,
          rgb(0, 0, 0) 2.83487px 0.981584px 0px,
          rgb(0, 0, 0) 2.35766px 1.85511px 0px,
          rgb(0, 0, 0) 1.62091px 2.52441px 0px,
          rgb(0, 0, 0) 0.705713px 2.91581px 0px,
          rgb(0, 0, 0) -0.287171px 2.98622px 0px,
          rgb(0, 0, 0) -1.24844px 2.72789px 0px,
          rgb(0, 0, 0) -2.07227px 2.16926px 0px,
          rgb(0, 0, 0) -2.66798px 1.37182px 0px,
          rgb(0, 0, 0) -2.96998px 0.42336px 0px,
          rgb(0, 0, 0) -2.94502px -0.571704px 0px,
          rgb(0, 0, 0) -2.59586px -1.50383px 0px,
          rgb(0, 0, 0) -1.96093px -2.27041px 0px,
          rgb(0, 0, 0) -1.11013px -2.78704px 0px,
          rgb(0, 0, 0) -0.137119px -2.99686px 0px,
          rgb(0, 0, 0) 0.850987px -2.87677px 0px,
          rgb(0, 0, 0) 1.74541px -2.43999px 0px,
          rgb(0, 0, 0) 2.44769px -1.73459px 0px,
          rgb(0, 0, 0) 2.88051px -0.838247px 0px;
      }

      .loading-bar-outer {
        height: 53px;
        width: 0%;
        background: #0080ce;
        border: 3px solid #000000;
        border-radius: 30px;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        animation: loadingbaranimation 3.5s;
      }

      .loading-bar-inner {
        height: 33px;
        background: #006cad;
        border: 2px solid #000000;
        border-radius: 30px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        animation: loadingbaranimation 3.5s;
      }
    }
  }
}

@keyframes loadingbaranimation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.moca-intro {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  max-width: 1440px;
  min-height: 786px;
  width: 100%;
  background-image: url(../../images/moca-intro-objects.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  @media only screen and (max-width: 1440px) {
    background-size: cover;
  }

  @media only screen and (max-width: 700px) {
    background-image: url(../../images/Moca-Instructions-mobile.svg);
    max-width: 100vw;
    min-height: 100vh;
  }

  .moca-hello {
    width: 628px;
    height: 628px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: url(../../images/moon.svg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (max-width: 700px) {
      width: 530px;
      height: 530px;
    }

    @media only screen and (max-width: 500px) {
      width: 430px;
      height: 430px;
    }
  }

  .moca-hello-inner {
    max-width: 480px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../../images/bluecircle.svg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (max-width: 700px) {
      padding: 0 30px;
      box-sizing: border-box;
      max-width: 452px;
      height: 450px;
    }

    @media only screen and (max-width: 500px) {
      max-width: 350px;
      height: 350px;
    }

    img.moca-icon {
      width: 220px;
      height: auto;
      position: relative;
      top: 40px;
    }
  }

  &-message {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    background: #ffffff;
    border: 2px solid #000000;
    border-radius: 30px;
    padding: 7px 12px;
    color: #0a0a0a;
    margin: 0 0 13px;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 900px) {
      padding: 10px 20px;
      margin: 0 0 8px;
    }
  }

  &-next.btn {
    margin: 0 auto;
    min-width: 298px;
    width: auto;
    height: 52px;

    &::before {
      margin-right: 10px;
    }
  }
}

.moca-instructions {
  display: block;
  width: 100vw;
  height: 100vh;

  &:before {
    content: '';
    display: block;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .moca-wrap {
    padding: 0 10%;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
    position: relative;

    @media only screen and (max-width: 700px) {
      justify-content: center;
      padding: 0 5%;
    }
  }

  .moca-character {
    position: relative;
    bottom: -30px;
    max-width: 362px;
    width: 100%;
    display: block;

    @media only screen and (max-width: 700px) {
      width: 150px;
    }

    img {
      width: 100%;
    }
  }
}

.socials-links {
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  cursor: url(../../images/cursor.png), auto;
  z-index: 1;

  img {
    max-width: 28px;
    width: 100%;
  }

  ul {
    cursor: url(../../images/cursor.png), auto;
    background: #ffcf00;
    border: 2px solid #000000;
    border-right: 0;
    border-radius: 20px 0px 0px 20px;
    background: #ffcf00;
    border: 2px solid #000000;
    border-right: 0;
    border-radius: 20px 0px 0px 20px;
    margin: 0;
    min-height: 187px;
    display: flex;
    flex-wrap: wrap;
    width: 46px;
    align-items: center;
    align-content: center;
    justify-content: center;
    &.bg-white {
      background: #ffffff;
    }
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 46px;
      border-bottom: 0;
      cursor: url(/assets/images/cursor.png), auto;

      &:nth-last-child(1) {
        height: 46px;
      }

      .socials {
        height: 100%;
        width: 100%;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    a {
      @extend .social-animation;
    }
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
}

.modal-wrapper {
  transition: visibility 0.15s linear;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10000;
  padding: 20px;
  box-sizing: border-box;

  @media only screen and (max-width: 900px) {
    padding: 20px 15px;
  }

  &.open {
    visibility: visible;

    .outer {
      transform: none;
    }
  }

  .outer {
    transition: transform 0.3s ease-out;
    transform: translateY(-70px);
    box-sizing: border-box;
    background: #ffafff;
    border: 3px solid #000000;
    border-radius: 20px;
    padding: 2px;
    display: block;
    width: 100%;
    height: 100%;
  }

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffafff;
    border: 3px solid #000000;
    border-radius: 20px;
    display: block;
    padding: 2px;
  }

  .content {
    width: 100%;
    height: 100%;
    background: #f1e699;
    border: 3px solid #000000;
    border-radius: 20px;
    overflow-wrap: break-word;
    font-weight: 600;
    padding: 30px 7px 45px;

    @media only screen and (max-width: 900px) {
      padding: 30px 7px 30px;
    }
  }

  .close {
    width: 55px;
    height: 55px;
    position: absolute;
    right: -17px;
    top: -15px;
    background: none;
    padding: 0;
    border: 0;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  h2 {
    &.title {
      font-weight: 700;
      font-size: 40px;
      line-height: 51px;
      text-transform: capitalize;
      text-align: center;

      @media only screen and (max-width: 900px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}

.modal-wappar {
  .success-checkbox {
    width: 230px;
    margin-left: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .success-top-left-modal {
    width: 140px;
    height: 140px;
    position: absolute;
    left: -18px;
    top: -35px;
  }

  .success-bottom-right-modal {
    width: 100px;
    height: 100px;
    position: absolute;
    right: -35px;
    bottom: -25px;
  }

  .modal-decoration {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.moca-tooltip {
  display: inline-flex;
  position: relative;

  &-label {
    display: inline-flex;
    svg {
      position: relative;
      bottom: -4px;
    }
  }

  &:hover {
    .moca-tooltip-content {
      opacity: 1;
      visibility: visible;
    }
  }

  &-content {
    background: #ffffff;
    border: 2px solid #000000;
    border-radius: 16px;
    bottom: 60px;
    left: 41%;
    max-width: 476px;
    opacity: 0;
    padding: 20px;
    position: absolute;
    transform: translateX(-41%);
    visibility: hidden;
    width: max-content;
    z-index: 10;
    box-shadow: 5px 7px 0 #000;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000;

    @media only screen and (max-width: 900px) {
      max-width: 320px;
      left: auto;
      right: 0;
      transform: translateX(25%);
      z-index: 8999;
    }

    @media only screen and (max-width: 374px) {
      max-width: 300px;
      transform: translateX(20%);
    }

    &:after {
      border-color: #000 transparent transparent;
      border-style: solid;
      border-width: 36px 20px 36px 20px;
      content: '';
      left: 37%;
      margin-left: 1px;
      position: absolute;
      top: 100%;
      z-index: 1;

      @media only screen and (max-width: 900px) {
        left: auto;
        right: 22%;
      }

      @media only screen and (max-width: 374px) {
        right: 26%;
      }
    }

    &::before {
      border-color: #fff transparent transparent;
      border-style: solid;
      border-width: 25px 15px;
      content: '';
      left: 37%;
      margin-left: 3px;
      position: absolute;
      top: 100%;
      z-index: 10;

      @media only screen and (max-width: 900px) {
        left: auto;
        right: 25%;
        margin-left: 0;
        margin-right: -3px;
      }

      @media only screen and (max-width: 374px) {
        right: 19%;
        margin-right: -2px;
      }
    }

    h2 {
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
      margin: 0 0 10px;

      @media only screen and (max-width: 900px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 18px;

      @media only screen and (max-width: 900px) {
        font-size: 14px;
      }
    }

    ul {
      margin: 0;
      list-style-type: disc;
      padding: 0 0 0 20px;
      @media only screen and (max-width: 900px) {
        font-size: 14px;
      }
      li {
      }
    }
  }
}

.moca-event-tooltip {
  @media only screen and (max-width: 374px) {
    display: block;
  }

  .moca-tooltip-content {
    bottom: 52px;
    right: -14%;
    transform: translateX(14%);
    left: auto;

    @media only screen and (max-width: 900px) {
      right: -9%;
      transform: translateX(9%);
    }

    @media only screen and (max-width: 374px) {
      right: 0;
      transform: translateX(9%);
    }

    &:after {
      margin-left: auto;
      left: auto;
      right: 13%;
      margin-right: -4px;

      @media only screen and (max-width: 900px) {
        right: 7%;
        margin-right: -2px;
      }

      @media only screen and (max-width: 374px) {
        right: 50%;
        transform: translateX(-50%);
        margin-right: -45px;
      }
    }
    &:before {
      margin-left: auto;
      left: auto;
      right: 14%;
      margin-right: -2px;

      @media only screen and (max-width: 900px) {
        right: 9%;
        margin-right: -1px;
      }

      @media only screen and (max-width: 374px) {
        right: 50%;
        transform: translateX(-50%);
        margin-right: -33px;
      }
    }
  }
}

.moca-what-tribe-tooltip {
  .moca-tooltip-content {
    padding: 23px 20px 5px;
  }
}

.mocana-wrapper.test{
  .event-title-wrapper {
      padding-top: 409px;
    }
    .test1{
       display: flex;
      justify-content: space-around;
      .left{
        color:#fff;
        max-width: 60%;
        padding-right: 20px;
        word-break: break-word;
      }
    }
  }

.list-tabs {
  ul {
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
    display: flex;
    width: 100%;
    border-bottom: 2px solid #000000;
    gap: 12px;
    li {
      padding: 0;
      button {
        background: transparent;
        padding: 16px 24px;
        border: 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #000000;
        filter: drop-shadow(6px 0px 0px #000000);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background: #ffffff;
        border: 2px solid #000000;
        bottom: -5px;
        position: relative;
        &.active {
          font-weight: 700;
          font-size: 22px;
          line-height: 24px;
          background: #ffcf00;
          bottom: -2px;
        }
      }
    }
  }
}
.tab-content {
  display: none;
  &.selected {
    display: block;
  }
  .leader-board-wrapper {
    position: relative;
    .show-tabs {
      position: absolute;
      top: 30px;
      right: 0;
      .btn-show {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        border: 0;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 52px;
        background: #ffcf00;
        border: 2px solid #000000;
        box-shadow: 3px 3px 0px #000000;
        border-radius: 100px;
        padding: 15px 20px;
        &.show {
          span {
            img {
              transform: rotate(180deg);
            }
          }
        }
        span {
        }
        img {
        }
      }
      .list-tabs.tabs-leaderboard {
        display: none;
        position: absolute;
        top: 120%;
        right: 0;
        &.show {
          display: block;
        }
        ul {
          background: #fff7d2;
          border: 2px solid #000000;
          box-shadow: 3px 3px 0px #000000;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          padding: 8px;
          z-index: 100;
          position: relative;
          li {
            min-width: 315px;
            button {
              box-shadow: unset;
              width: 100%;
              background: transparent;
              filter: unset;
              border-radius: 12px;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              color: #333333;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 8px 12px;
              position: relative;
              border-radius: 4px;
              gap: 5px;
              border: 0;
              bottom: 0;
              > span {
                width: 28px;
              }
              &.active {
                background: #ffcf00;
                color: #000000;
                &::after {
                  content: '';
                  position: absolute;
                  display: inline-block;
                  top: 8px;
                  right: 12px;
                  transform: rotate(45deg);
                  height: 14px;
                  width: 8px;
                  border-bottom: 3px solid #000000;
                  border-right: 3px solid #000000;
                }
              }
            }
          }
        }
      }
    }
  }
  .moca-table {
    max-width: 1020px;
    margin: auto;
    padding: 0px;
    .sort-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .moca-search-filter {
        margin: 0;
        width: 56%;
        .mocana-filter {
          display: none;
        }
        .moca-search {
          width: 100%;
          border: 2px solid #000000;
        }
      }
      .sort-tabs {
        display: inline-flex;
        box-shadow: 4px 4px 0px #000000;
        border-radius: 12px;
        border: 2px solid #000000;
        overflow: hidden;
        margin-bottom: 0;
        button.btn-sort {
          padding: 8px 16px;
          background: #ffffff;
          color: #000;
          border: 2px solid #000000;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          border: 0;
          position: relative;
          &:first-child {
            &::before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 2px;
              background: #000000;
            }
          }
          &.active {
            background: #ffcf00;
            font-weight: 700;
          }
        }
      }
    }
    .moca-title {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      color: #ffffff;
      display: flex;
      align-items: center;
      gap: 8px;
      padding-bottom: 32px;
      margin-bottom: 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      > img {
        max-height: 32px;
      }
    }
    &.realm-table {
      .moca-results {
        .table-container {
          .table-container__header.active-nickname {
            .table-container__header--col-5::after {
              transform: none;
            }
          }
          &__item.mobile {
            .table-container__item-bottom {
              .table-container__item--col-2 {
                width: unset;
                margin: 0;
                align-items: flex-start;
                .table-container__item-own {
                  margin-right: 0;
                }
                .register-wallet {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 20px;
                  color: #0080ce;
                  text-decoration: underline;
                }
              }
              .table-container__item-bottom-right {
                .table-container__item-own {
                  margin-right: 0;
                }
                .table-container__item--col-5 {
                  font-weight: 700;
                  font-size: 28px;
                  line-height: 34px;
                  text-align: right;
                  color: #000000;
                }
              }
            }
          }
          &__item {
            &--col-5 {
              min-width: 137px;
            }
            &--col-2 {
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;
              color: #000000;
            }
          }
        }
      }
      .table-container__header {
        &.active-nickname {
          .table-container__header--col-2::after {
            transform: rotate(180deg);
          }
        }
        &.active-wallet_address {
          .table-container__header--col-4::after {
            transform: rotate(180deg);
          }
        }
        &.active-max_score {
          .table-container__header--col-5::after {
            transform: rotate(180deg);
          }
        }
      }
    }
    .table-container {
      width: 100%;

      &__header {
        &.active-moca_name {
          .table-container__header--col-2::after {
            transform: rotate(180deg);
          }
        }
        &.active-rank,
        &.active-rank_max_score {
          .table-container__header--col-1::after {
            transform: rotate(180deg);
          }
        }
        &.active-tribe {
          .table-container__header--col-4::after {
            transform: rotate(180deg);
          }
        }
        &.active-nickname {
          .table-container__header--col-5::after {
            transform: rotate(180deg);
          }
        }
        &.active-max_score {
          .table-container__header--col-6::after {
            transform: rotate(180deg);
          }
        }
        display: flex;
        align-items: center;
        margin: 20px 0 8px 0;
        padding: 0 50px;
        justify-content: space-between;
        span {
          color: #fff;
          font-weight: 700;
          font-size: 14px;
        }
        &--col-1 {
          min-width: 100px;
        }
        &--col-2 {
          min-width: 140px;
        }
        &--col-4 {
          min-width: 130px;
        }
        &--col-5 {
          min-width: 108px;
        }
        &--col-6 {
          min-width: 120px;
          justify-content: end;
          left: 36px;
        }
        &--col-1,
        &--col-2,
        &--col-3,
        &--col-4,
        &--col-5,
        &--col-6 {
          display: flex;
          align-items: center;
          gap: 4px;
          position: relative;
          &:hover {
            .moca-table-tooltip {
              visibility: visible;
              opacity: 1;
              box-shadow: 6px 5px 0px #000000;
              &:before {
                content: '';
                position: absolute;
                top: 100%;
                left: 40%;
                margin-left: -5px;
                border-width: 25px;
                border-left-width: 15px;
                border-right-width: 15px;
                border-style: solid;
                border-color: #fff transparent transparent transparent;
                z-index: 10;
              }
              &:after {
                content: '';
                position: absolute;
                top: 100%;
                left: 39%;
                margin-left: -5px;
                border-width: 36px;
                border-left-width: 24px;
                border-right-width: 20px;
                border-style: solid;
                border-color: #000000 transparent transparent transparent;
                z-index: 1;
              }
            }
          }
        }
        &--col-1::after,
        &--col-2::after,
        &--col-3::after,
        &--col-4::after,
        &--col-5::after,
        &--col-6::after {
          content: '';
          display: block;
          width: 15px;
          height: 15px;
          background-image: url(../../images/arrow-sort.svg);
          background-position-y: -1px;
        }
        &--col-3::before,
        &--col-6::before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          background-image: url(../../images/info.svg);
          display: none;
        }
      }

      &__item {
        display: flex;
        align-items: center;
        padding: 8px 50px;
        background: #fff9be;
        border: 1px solid #000000;
        border-radius: 12px;
        margin-bottom: 10px;
        background-repeat: no-repeat;
        background-image: url(../../images/item-table-background.svg);
        justify-content: space-between;
        &.mobile {
          display: none;
          background-image: unset;
          .table-container__item--col-4 {
            min-width: unset;
          }
          .table-container__item--col-2 {
            .link-opensea {
              img {
                max-width: 35px;
                max-height: 35px;
              }
            }
          }
        }
        &--col-1 {
          min-width: 110px;
        }
        &--col-2 {
          max-width: 135px;
        }
        &--col-4 {
          max-width: 150px;
        }
        &--col-5 {
          max-width: 160px;
        }
        &--col-6 {
          min-width: 100px;
          padding-right: 0;
        }
        &--col-1 {
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;
          color: #000000;
        }
        &--col-2 {
          display: flex;
          align-items: center;
          gap: 8px;
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 1px solid #545454;
          }
          span {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
          }
          .link-opensea {
            img {
              max-width: 30px;
              max-height: 30px;
            }
          }
        }
        &--col-3 {
          font-weight: 700;
          font-size: 14px;
          color: #00cd3c;
          position: relative;
          &.not {
            color: red;
            div {
              color: #000;
            }
            span {
              display: flex;
              align-items: center;
            }
          }
          &:hover {
            .moca-table-tooltip {
              visibility: visible;
              opacity: 1;
              padding: 12px;
              p {
                font-size: 12px;
                font-weight: 500;
                margin: 3px 0;
                b {
                  font-weight: bold;
                }
              }
            }
          }
        }
        &--col-4 {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #636363;
          text-transform: capitalize;
        }
        &--col-5 {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #000000;
          span {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
          }
        }
        &--col-6 {
          // text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
          //       1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
          font-weight: 800;
          font-size: 20px;
          line-height: 34px;
          text-align: right;
          color: #000000;
        }
      }

      &__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1.5rem auto;
        gap: 16px;
        &--prev,
        &--next {
          img {
            transform: rotate(90deg);
            width: 22px;
            margin-top: 3px;
          }
        }
        &--next img {
          transform: rotate(-90deg) !important;
        }
        select {
          font-family: inherit;
          font-size: 1.4rem;
          font-weight: 800;
          padding: 8px 28px;
          width: 100px;
          border-radius: 30px;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-image: url(../../images/arrow-down.svg);

          background-repeat: no-repeat;
          background-position: right 0.8em top 50%;
          background-size: 1em auto;
          box-shadow: 4px 4px 0px #000000;
          background-color: #f8d748;
        }
        &--select {
          font-family: inherit;
          font-size: 1.4rem;
          font-weight: 800;
          padding: 10px 28px;
          width: 110px;
          border-radius: 30px;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-image: url(../../images/arrow-down.svg);
          background-repeat: no-repeat;
          background-position: right 0.8em top 50%;
          background-size: 1em auto;
          box-shadow: 4px 4px 0px #000000;
          background-color: #f8d748;
          position: relative;
          &--option {
            background: #f8d748;
            border: 1px solid #000000;
            box-shadow: 4px 4px 0px #000000;
            border-radius: 8px;
            position: absolute;
            width: 100%;
            bottom: 110%;
            left: 0;
            ul {
              margin: 0;
              padding: 0;
              max-height: 246px;
              overflow: scroll;
              &::-webkit-scrollbar {
                background: none;
                height: 6px;
                width: 7px;
              }
              &::-webkit-scrollbar-corner {
                background: none;
              }
              &::-webkit-scrollbar-thumb {
                background: rgba(136, 111, 85, 0.5);
                border-radius: 100px;
              }
              li {
                padding: 6px 16px;
                &:hover {
                  background: #ffa800;
                }
              }
            }
            .last-item {
              border-top: 1px solid rgba(0, 0, 0, 0.5);
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              padding: 6px 16px;
              &:hover {
                background: #ffa800;
              }
            }
          }
        }

        &--label {
          color: #fff;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
    .moca-404 {
      padding: 5%;
      text-align: center;
      color: #fff;
      &.loading {
        min-height: 500px;
      }
      .loading-icon {
        max-width: 100px;
        margin-top: 2rem;
      }
      h3 {
        font-size: 20px;
        font-weight: bold;
        margin: 1rem 0;
      }
    }
    .moca-table-tooltip {
      max-width: 430px;
      position: absolute;
      background: white;
      z-index: 10;
      visibility: hidden;
      opacity: 0;
      bottom: 55px;
      left: 40%;
      transform: translateX(-50%);
      width: max-content;
      border: 2px solid #000000;
      border-radius: 10px;
      padding: 1rem;
      h2 {
        font-weight: bold;
        font-size: 1.3rem;
      }
      p {
        margin: 12px 0;
      }
      ul {
        list-style: disc;
        margin-left: 1.1rem;
        margin-bottom: 0;
        li:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }
  .tribe-content {
    margin-top: 16px;
    padding-bottom: 90px;
    .tribe-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 0;
      .text-large {
        font-weight: 800;
      }
    }
    .tribe-rank-list {
      margin-top: 67px;
      .tribe-rank-1 {
        padding: 12px 13px;
        height: 96px;
        background: #fff9be;
        border: 1px solid #000000;
        border-radius: 12px;
        position: relative;
        &::before {
          content: '';
          background: url(../../images/1st.svg);
          background-repeat: no-repeat;
          height: calc(100% - 2px);
          width: 167px;
          position: absolute;
          top: 1px;
          left: 1px;
          border-radius: 12px;
        }
        .rank-number {
          width: 72px;
          height: 69px;
          background: url(../../images/star.svg);
          background-repeat: no-repeat;
          background-size: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 12px;
          left: 13px;
          font-weight: 700;
          font-size: 40px;
          line-height: 48px;
          color: #000000;
        }
        .rank-point {
          background: linear-gradient(
            90deg,
            #ffd056 0%,
            #ffde88 24.48%,
            #ffef99 42.19%,
            #fff2ad 73.96%,
            #ffd056 100%
          );
          border-radius: 40px;
          margin-bottom: 0;
          padding: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-weight: 800;
          font-size: 40px;
          line-height: 40px;
          color: #000000;
          padding-left: 12%;
        }
        .rank-image {
          display: flex;
          position: absolute;
          bottom: 0;
          left: 108px;
        }
        .rank-tags {
          position: absolute;
          top: 0;
          right: 0;
          background: linear-gradient(
            274.06deg,
            #ffce50 4.85%,
            #ffd51d 42.79%,
            #ffb039 98.23%
          );
          padding: 12px 22px 15px 36px;
          font-weight: 700;
          font-size: 20px;
          line-height: 16px;
          color: #000000;
          border-top-right-radius: 12px;
          border-bottom-left-radius: 40px;
          text-transform: uppercase;
        }
      }
      .tribe-ranks {
        display: flex;
        flex-wrap: wrap;
        gap: 28px;
        margin-top: 27px;
        &.same-rank {
          .tribe-rank-1 {
            .rank-point {
              background: none;
              padding: 0;
              width: auto;
            }
            .rank-number {
              top: 5px;
              width: 64px;
              height: 64px;
            }
            .rank-image {
              img {
                max-width: 100%;
                height: 106px;
              }
              max-width: 106px;
            }
          }
        }
        .tribe-rank {
          border: 1px solid #000000;
          border-radius: 12px;
          height: 72px;
          padding: 16px 34px;
          width: 355px;
          max-width: 48%;
          position: relative;
          &.tribe-rank-2 {
            background-image: url(../../images/2nd.svg);
            background-repeat: no-repeat;
            background-color: #e5fffd;
            .rank-tags {
              background: linear-gradient(
                91.9deg,
                #3b9fbe 4.72%,
                #71f7ff 35.98%,
                #83fff9 71.77%,
                #3ec8e7 100%
              );
            }
          }
          &.tribe-rank-3 {
            background-image: url(../../images/3rd.svg);
            background-repeat: no-repeat;
            background-color: #ffd7a9;
            .rank-tags {
              background: linear-gradient(
                91.9deg,
                #6b4630 4.72%,
                #f2864a 32.45%,
                #ffa53a 71.77%,
                #bf6938 100%
              );
            }
          }
          &.tribe-rank-4 {
            background-image: url(../../images/4th.svg);
            background-repeat: no-repeat;
            background-color: #ffffff;
            .rank-tags {
              background: #ededed;
            }
          }
          &.tribe-rank-5 {
            background-image: url(../../images/5th.svg);
            background-repeat: no-repeat;
            background-color: #ffffff;
            .rank-tags {
              background: #ededed;
            }
          }
          .rank-number {
            font-weight: 700;
            font-size: 40px;
            line-height: 40px;
            color: #000000;
          }
          .rank-image {
            display: flex;
            position: absolute;
            left: 85px;
            bottom: 0;
          }
          .rank-tags {
            padding: 8px 17px 8px 23px;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            position: absolute;
            top: 0;
            right: 0;
            border-top-right-radius: 12px;
            border-bottom-left-radius: 40px;
            text-transform: uppercase;
          }
          .rank-point {
            margin: 0;
            font-weight: 800;
            font-size: 24px;
            line-height: 24px;
            color: #000000;
            position: absolute;
            right: 40px;
            bottom: 10px;
          }
        }
      }
    }
  }
  .table-container__pagination--select {
    border: 1px solid #000000;
  }
}

@media only screen and (max-width: 991px) {
  .tab-content {
    .leader-board-wrapper {
      .show-tabs {
        top: 48px;
        .btn-show {
          height: 28px;
          width: 28px;
          padding: 5px;
          .text {
            display: none;
          }
          span {
            img {
              bottom: 2px;
              position: relative;
            }
          }
        }
      }
    }
    .moca-table {
      padding: 0;
      .moca-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        margin-bottom: 16px;
        padding-bottom: 0;
        border-bottom: 0;
      }
      .sort-filter {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        font-size: 1rem;
        .moca-search-filter {
          flex-direction: row;
          width: 100%;
          .moca-search {
            width: 100%;
            padding: 5px 12px;
            img {
              width: 25px;
            }
            input {
              min-height: unset;
              padding: 0 0 0 6px;
              &::placeholder {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: rgba(0, 0, 0, 0.3);
              }
            }
          }
          .mocana-filter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;
            .mocana-button-filter {
              img {
                opacity: 0.5;
                max-width: 16px;
              }
              button {
                padding: 0;
                box-shadow: none;
                border: 0;
                flex-direction: row-reverse;
                background: transparent;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
                > img {
                  margin-left: 6px;
                  filter: brightness(0) invert(1);
                  opacity: 1;
                }
              }
            }
            .mocana-button-sort {
              display: block;
              button {
                border: 0;
                background: transparent;
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
                gap: 6px;
                span {
                  display: flex;
                  flex-direction: column;
                  > img {
                    filter: brightness(0) invert(1);
                    &:first-child {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }
          }
          .mocana-list-filter,
          .mocana-list-sort {
            position: fixed;
            bottom: 0;
            top: unset;
            left: 0;
            width: 100%;
            max-height: 75%;
            z-index: 100;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            .filter-wrapper,
            .sort-wrapper {
              overflow: scroll;
            }
            h2 {
              justify-content: flex-start;
              .text-filter {
                order: 2;
                margin-left: 20%;
              }
              .text-reset {
                order: 1;
              }
            }
            .toggle-button {
              position: absolute;
              top: -28px;
              right: 21px;
              width: 55px;
              height: 55px;
              border-radius: 50%;
              border: 3px solid #000000;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #f8d748;
            }
          }
          .mocana-list-sort {
            // display: block;
          }
        }
        .sort-tabs {
          button.btn-sort {
            font-size: 1rem;
            line-height: 20px;
          }
        }
      }

      .moca-results {
        .moca-404 {
          padding: 3% 0;
          text-align: center;
          .loading-icon {
            max-width: 60px;
          }
          img {
            max-width: 80px;
          }
          h3 {
            font-size: 18px;
            line-height: 25px;
          }
          p {
            color: rgba(255, 255, 255, 0.8);
          }
        }
        .table-container__header {
          display: none;
        }
        .table-container {
          &__item {
            display: none;
            &.mobile {
              display: block;
              padding: 0;
              position: relative;
            }
            &-top {
              display: flex;
              padding: 6px 14px;
              border-bottom: 1px solid;
              align-items: center;
              justify-content: space-between;
              background-repeat: no-repeat;
              background-image: url(../../images/item-table-background.svg);
            }
            &-bottom {
              padding: 6px 14px;
              display: flex;
              span.label {
                display: flex;
                align-items: center;
                font-size: 0.85rem;
                font-weight: bold;
                color: gray;
                font-family: 'Montserrat';
                margin-bottom: 4px;
              }
              &-right {
                &-flex {
                  display: flex;
                  margin-top: 18px;
                  &:first-child {
                    margin: 0;
                    .table-container__item--col-5 {
                      font-weight: 700;
                      font-size: 28px;
                      line-height: 34px;
                      color: #000000;
                    }
                  }
                }
              }
            }
            &--col-4 {
              width: auto;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              color: #000000;
            }
            &--col-5 {
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              color: #000000;
            }
            &--col-2 {
              min-width: 50px;
              flex-direction: column;
              font-weight: bold;
              width: fit-content;
              margin-right: 28px;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              color: #000000;
              > span {
                font-size: 24px;
                top: 27px;
                position: relative;
              }
              img {
                width: 68px;
                height: 68px;
              }
            }
            &-own {
              margin-right: 28px;
            }
            &-eligibility,
            &-xp {
              .label::before {
                content: '';
                display: block;
                width: 13px;
                height: 13px;
                background-image: url(../../images/mocana/icon-tooltip-mb.svg);
                background-size: contain;
                margin-right: 2px;
              }
            }
          }
        }
      }
      .moca-table-tooltip-modal {
        visibility: visible;
        background-image: url(../../images/mocana/tooltip-modal.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        min-height: 314px;
        padding: 25px 45px;
        position: fixed;
        top: 25%;
        left: 25%;
        width: 100%;
        max-width: 349px;
        z-index: 100;
        .toggle-button {
          position: absolute;
          top: -3px;
          right: -1px;
          width: 55px;
          height: 55px;
          border-radius: 50%;
          border: 3px solid #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f8d748;
        }
        .tooltip-title {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          margin-bottom: 9px;
        }
        .tooltip-description {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          margin-bottom: 0;
          a {
            text-decoration: none;
            color: #000;
          }
        }
      }
    }
    .tribe-content {
      padding-bottom: 0;
      p.tribe-title {
        font-size: 16px;
        line-height: 20px;
      }
      .tribe-rank-list {
        margin-top: 38px;
        .tribe-rank-1 {
          height: 72px;
          background-image: url(../../images/1st.svg);
          background-repeat: no-repeat;
          position: relative;
          &::before {
            display: none;
          }
          .rank-number {
            width: 64px;
            height: 64px;
            margin: 0;
            top: 4px;
            font-weight: 700;
            font-size: 40px;
            line-height: 40px;
            color: #000000;
          }
          .rank-image {
            left: 88px;
            img {
              max-width: 106px;
              &.connector {
                max-width: 93px;
              }
              &.angel {
                max-width: 106px;
              }
              &.builders {
                max-width: 106px;
              }
              &.dreamer {
                max-width: 88px;
              }
              &.neo-capitalist {
                max-width: 102px;
              }
            }
          }
          .rank-tags {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            padding: 8px 17px 8px 28px;
          }
          .rank-point {
            background: transparent;
            padding: 0;
            position: absolute;
            font-weight: 800;
            font-size: 24px;
            line-height: 24px;
            color: #000000;
            width: unset;
            bottom: 10px;
            right: 40px;
          }
        }
        .tribe-ranks {
          .tribe-rank {
            width: 100%;
            max-width: 100%;
            .rank-image {
              img {
                &.connector {
                  max-width: 93px;
                }
                &.angel {
                  max-width: 106px;
                }
                &.builders {
                  max-width: 106px;
                }
                &.dreamer {
                  max-width: 88px;
                }
                &.neo-capitalist {
                  max-width: 102px;
                }
              }
            }
          }
        }
      }
    }
  }
  .list-tabs {
    ul {
      li {
        button {
          padding: 6px 10px;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          &.active {
            padding: 8px 9px;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .event-main .tab-content .moca-table .table-container__item--col-6 {
    text-align: center;
  }
}

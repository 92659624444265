.event-main {
  flex: 1;
  @media only screen and (min-width: 992px) {
    min-width: 698px;
  }
  @media only screen and (max-width: 900px) {
    flex: 100%;
    z-index: 10;
  }
  .event-description {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 14px;
    .text-color {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #ffcf00;
    }
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  .event-description-mobile {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin-bottom: 14px;
    display: none;
    .text-color {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #ffcf00;
    }
    @media only screen and (max-width: 767px) {
      display: block;
    }
  }
}

.event-bar {
  &-wrapper {
    background: #0080ce;
    border-radius: 9px 9px 0 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 15px 22px 13px 24px;
    margin: -12px -12px 12px;
  }

  &-not-started {
    padding: 15px 15px 13px 24px;
  }

  &-title {
    padding: 0 5px 0 0;
  }

  &-subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    .date {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.event-msg {
  &-wrapper {
    background: #bfff7f;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    padding: 6px 12px 6px 50px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 12px;
    @media only screen and (max-width: 900px) {
      padding: 6px 27px 6px 42px;
    }

    &.error {
      background: #ff9cd7;
    }
    &.success {
      .event-msg-icon {
        margin-top: -3px;
      }
    }
  }

  &-with-realm {
    .event-msg-icon {
      margin: -15px 8px 0 -34px;
    }
    .event-msg {
      max-width: 90%;
    }
  }

  &-icon {
    margin: 0 10px 0 -34px;
  }

  &-realm {
    img {
      margin: 0 0 -7px 5px;
      position: relative;
      top: -3px;
    }
  }

  a {
    display: inline-flex;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #0080ce;
    margin: 0 0 0 -37px;
  }
}

.event-main-msg {
  max-width: calc(100% - 60px);

  &-wrapper {
    background: #bfff7f;
    padding: 8px 12px;
    margin: -12px -12px 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    text-align: center;
    min-height: 36px;

    @media only screen and (max-width: 900px) {
      margin: -14px -12px 6px;
    }

    &.error {
      background: rgba(255, 0, 0, 0.2);
      color: #ff0000;
    }
  }

  &-icon {
    margin: -4px 7px 0 0;
    min-width: 24px;
  }

  .txn-hash-link {
    font-size: 12px;
    line-height: 16px;
    color: #0080ce;
    display: inline-flex;
    padding: 4px 0 0;
    margin: 0 0 0 -32px;
  }
}

.event-countdown {
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #fff;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: -5px 0;

  &-item {
    padding: 0 3px;
  }
}

.event-sb {
  width: 455px;
  padding: 8px 0 0 49px;
  position: relative;
  .loading-state {
    height: 50px;
    animation: skeleton-loading 1s linear infinite alternate;
    &.h-100 {
      margin-top: 40px;
      height: 155px;
    }
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  @media only screen and (max-width: 900px) {
    max-width: 100%;
    margin: 0 -12px;
    width: calc(100% + 24px);
    position: fixed;
    bottom: 0;
    z-index: 9001;
    padding: 0;
  }

  &-cover-bg {
    background: #fff;
    border: 2px solid #000;
    border-radius: 12px;
    position: absolute;
    z-index: 0;
    width: calc(100% - 50px);
    height: 100%;
  }

  &-panel {
    border-radius: 12px;
    min-height: 346px;
    padding: 12px;
    background: #fff;
    border: 2px solid #000000;
    position: relative;
    z-index: 2;
    margin: 0;
  }

  &-bg {
    position: absolute;
    width: 435px;
    height: 375px;
    z-index: 1;

    @media only screen and (max-width: 900px) {
      background-size: cover;
      width: 320px;
      height: 240px;
    }

    &.top-bg {
      top: -10px;
      left: 32px;
      background: url(../../images/mocana/event-sb-top-bg.png) 0 0 no-repeat;
      @media only screen and (max-width: 900px) {
        top: -10px;
        left: -15px;
      }
    }

    &.bottom-bg {
      bottom: -35px;
      right: -28px;
      background: url(../../images/mocana/event-sb-bottom-bg.png) 100% 100%
        no-repeat;
      @media only screen and (max-width: 900px) {
        bottom: -8px;
        right: -15px;
      }
    }
  }

  &-header {
    padding: 0 0 0 12px;
    margin: 0 0 12px;
  }

  &-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  &-subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    padding: 10px 0 0;
  }

  &-note {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 0 0;
  }

  &-main {
    background: #ededed;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    justify-content: center;
    min-height: 100px;
    margin: 0;
    align-items: center;
    flex-direction: column;

    p {
      margin: 0;
    }
  }

  &-actions {
    padding: 15px 0 12px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .btn-register-now {
      margin: 0 0 7px;
    }
  }

  &-action-note {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    text-align: center;
    padding: 16px 0 0;
    margin: 0;
    width: 100%;
  }

  &-or {
    &-actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      padding: 10px 0 0;

      .btn-register-with-realms {
        &:disabled {
          img {
            opacity: 0.5;
          }
        }
      }
    }

    &-txt {
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      width: 100%;
      text-align: center;
      position: relative;
      margin: 0 0 7px;

      &:before {
        content: '';
        background: #bfb47c;
        width: calc(100% - 23px);
        height: 1px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      span {
        background: #fff;
        display: inline-block;
        padding: 0 13px;
        position: relative;
      }
    }

    &-img {
      margin: 0 4px;
    }

    &-no-ticket {
      color: #ff0000;
    }

    &-note {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 20px 0 0;
      width: 100%;

      // &.no-ticket {
      //   padding: 11px 0 10px;
      // }

      a {
        color: #0080ce;
        font-weight: 700;
      }

      &-img {
        margin: 0 3px;
      }

      p {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        padding: 0 12px 0 0;
      }
    }
  }

  &-mobile {
    display: none;

    @media only screen and (max-width: 900px) {
      display: block;
      z-index: 100;
      &.event-sb-mobile-collapse {
        .event-sb-panel {
          min-height: 50px;
        }
        bottom: -24px;
        .event-bar-wrapper::after {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          background-position-y: -1px;
          background-size: cover;
          position: absolute;
          top: 12px;
          right: 12px;
          z-index: 10;
          transform: rotate(-180deg);
          background-image: url(../../images/arrow-down.svg);
        }
      }
      &.hide {
        .event-sb-panel > div:not(.event-bar-wrapper) {
          visibility: hidden;
          height: 0;
          margin: 0;
          padding: 0;
        }
      }
      &.show {
        bottom: 0;
        .event-bar-wrapper::after {
          transform: rotate(0);
        }
        .event-sb-panel > div:not(.event-bar-wrapper) {
          visibility: visible;
          height: auto;
          transition: all 0.1s ease;
        }
      }
    }

    .event-sb {
      &-panel {
        min-height: 128px;
        border: 0;
        border-radius: 12px 12px 0 0;

        @media only screen and (max-width: 900px) {
          display: flex;
          flex-direction: column;
        }
      }
      &-actions {
        &.event-closed {
          @media only screen and (max-width: 900px) {
            flex-direction: column;
            align-items: center;
          }
          button.event-closed {
            img {
              max-width: 24px;
              margin-right: 6px;
            }
            a {
              color: #000;
              text-decoration: none;
            }
          }
          .event-sb-title {
            font-size: 16px;
            margin-top: 14px;
            text-align: center;
          }
          .event-sb-action-note {
            padding-top: 6px;
          }
        }
        padding: 0;

        .btn-connect-wallet {
          margin: 0 0 12px;
        }
      }
    }

    .event-bar {
      &-not-started {
        @media only screen and (max-width: 900px) {
          flex-wrap: wrap;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }

  &-result {
    display: block;
  }

  &-modal {
    @media only screen and (max-width: 900px) {
      transition: visibility 0.15s linear;
      visibility: hidden;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 12px;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 7px;

      &.show-popup {
        visibility: visible;
        z-index: 9010;

        .event-sb-inner {
          transform: none;
        }
      }

      .event-bar {
        &-wrapper {
          flex-wrap: wrap;
          justify-content: center;
          padding: 10px 20px;
          min-height: 70px;
          margin: -12px -12px 14px;
        }

        &-title {
          padding: 0;
          margin: 0 0 7px;
          width: 100%;
          text-align: center;
        }
      }

      .event-sb {
        &-note {
          text-align: center;
        }
        &-main {
          position: relative;

          &:after {
            content: '';
            width: calc(100% - 6px);
            height: 9px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #ededed;
            border-radius: 0 0 8px 8px;
            z-index: 1;
          }
        }
      }
    }

    .event-sb-inner {
      @media only screen and (max-width: 900px) {
        transition: transform 0.3s ease-out;
        transform: translateY(-70px);
        max-width: 375px;
        position: relative;
      }
    }
  }

  &-close-btn {
    display: none;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    z-index: 3;
    position: absolute;
    right: 0;
    top: -13px;

    @media only screen and (max-width: 900px) {
      display: block;
    }
  }
}

.event-connect-wallet {
  padding: 20px 12px 32px;
  &.event-closed {
    padding: 55px 0;
    img {
      width: 24px;
      margin-right: 8px;
    }
    p {
      text-align: center;
      font-weight: 500;
      margin-top: 16px;
    }
    a {
      text-decoration: none;
      color: #000;
    }
  }

  .wallet-icon {
    margin: 0 0 15px;
  }

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 24px;
  }

  .btn {
    margin: 0;
  }
}

.event-select-moca {
}

.event-moca {
  &-scroller {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 276px;
    padding: 6px 12px 0;
    margin: -6px -12px -6px;
    width: 100%;

    @media only screen and (max-width: 900px) {
      max-height: 264px;
      padding: 6px 12px 0;
      margin: -2px -12px -8px;
    }

    &::-webkit-scrollbar {
      background: none;
      height: 12px;
      width: 6px;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 100px;
    }
  }

  &-grid {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
    @media only screen and (max-width: 900px) {
      margin: 0 -8px;
    }
  }

  &-item {
    width: 33.33%;
    padding: 0 6px;
    margin: 0 0 7px;

    @media only screen and (max-width: 900px) {
      padding: 0 8px;
      margin: 0 0 12px;
    }
  }

  &-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  &-img {
    background: #d9d9d9;
    border: 2px solid #000000;
    box-shadow: 2px 2px 0px #000000;
    font-size: 0;
    line-height: 0;
    position: relative;
    margin: 0 0 4px;

    img {
      max-width: 100%;
    }

    &.selected {
      &::before {
        content: '';
        border: 3px solid #00cd3c;
        background: transparent;
        width: calc(100% - 5px);
        height: calc(100% - 6px);
        pointer-events: none;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
      }
    }
  }

  &-info {
    background: rgba(35, 35, 35, 0.72);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4px 5px;
    min-height: 36px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #fff;

    @media only screen and (max-width: 900px) {
      background: rgba(0, 0, 0, 0.7);
      font-size: 12px;
      line-height: 16px;
      padding: 2px 5px;
      min-height: 32px;
    }
  }

  &-name {
    margin: 0;
  }

  &-tribe {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: #00d1ff;
    text-transform: capitalize;
  }

  &-xp {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  &-icon {
    position: absolute;
    right: -7px;
    z-index: 1;
    top: -7px;
  }
}

.event-no-moca {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-icon {
    margin: 13px 0 9px;
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 9px;
  }

  p {
    margin: 0 0 27px;
  }

  &-note {
    margin: 0 0 12px;
  }

  .btn-get-moca {
    margin: 0 0 20px;

    &-icon {
      margin: 0 4px 0 0;
    }
  }
}

.event-result {
  margin: 0 12px 2px;
  border-bottom: 1px solid #c2c2c2;

  @media only screen and (max-width: 900px) {
    border-top: 1px solid #c2c2c2;
    border-bottom: 0;
    order: 1;
    margin: 12px 12px -20px;
    padding: 16px 0 0;
    display: none;

    &.show {
      display: block;
    }
  }

  &.error {
    border-bottom: 0;
    display: flex;
    align-items: center;
    min-height: 265px;
    width: 100%;
    flex-wrap: wrap;
    align-content: center;
  }

  > p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #333;
    margin: 0 0 22px;
  }

  &-header {
    padding: 22px 0 0 12px;
    margin: 0 0 10px;

    @media only screen and (max-width: 900px) {
      padding: 0;
      margin: 0 0 24px;
      text-align: center;
    }
  }

  // &-actions {
  // }

  &-note {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 23px 0 0;
    margin: 0 0 12px;

    @media only screen and (max-width: 900px) {
      display: none;
    }
  }

  &-download-links {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;

    @media only screen and (max-width: 900px) {
      width: 100%;
      justify-content: center;
      padding: 24px 0 0;
    }

    li {
      padding: 0 6px;

      @media only screen and (max-width: 900px) {
        width: 134px;
        width: 145px;
      }

      img {
        max-width: 100%;
      }
    }
  }

  &-moca {
    &-list {
      margin: 0 0 12px;
      display: flex;
      flex-direction: column;
    }

    &-item {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #333;
    }
    &-msg {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #ff0000;
      margin: 0;
    }
    &-img {
      background: #d9d9d9;
      border: 2px solid #000000;
      box-shadow: 2px 2px 0px #000000;
      font-size: 0;
      line-height: 0;
      width: 80px;
      height: 80px;
      position: relative;

      img {
        max-width: 100%;
      }

      &.error {
        &::before {
          content: '';
          border: 2px solid #ff0000;
          background: transparent;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          pointer-events: none;
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: 1;
        }
      }
    }
    &-box {
      flex: 1;
      padding: 0 0 0 15px;
      &.error {
        padding: 0;
      }
    }
    &-info {
      display: flex;
      flex-wrap: wrap;
      padding: 5px 0 0;
    }
    &-name {
      margin: 0 17px 0 0;
    }
    &-xp {
      margin: 0 17px 0 0;
    }
    &-tribe {
      margin: 0;
      color: #00d1ff;
      text-transform: capitalize;
    }

    &-actions {
      display: none;

      @media only screen and (max-width: 900px) {
        order: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 14px 0 5px;
      }

      button,
      a {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #0080ce;
        border: 0;
        margin: 0;
        padding: 0;
        background: none;
        text-decoration: underline;
        display: inline-flex;
        align-items: center;
      }

      &-icon,
      svg {
        margin: 0 5px 0 0;
      }
    }
  }
}

.faq-section {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  padding: 90px 0 0;
  box-sizing: border-box;

  @media only screen and (max-width: 900px) {
    padding: 150px 20px 0;
    min-height: 0;
    height: auto;
    justify-content: flex-start;
  }

  &.bg {
    background-image: url(../../images/faq-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .faq {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 913px;
    width: 100%;
    margin: 0 auto 108px;

    @media only screen and (max-width: 900px) {
      padding: 0 9px 0 0;
      margin: 0 auto 44px;
    }

    .logo-block {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      max-width: 150px;

      .faq-title {
        max-width: 300px;
        width: 100%;
      }
    }

    .faq-block {
      width: 100%;
    }

    .btn-wrap {
      background: #ffafff;
      box-sizing: border-box;
      padding: 7px 10px 7px 7px;
      cursor: url(../../images/cursor.svg), auto;

      @media only screen and (max-width: 900px) {
        padding: 7px;
      }
    }

    .accordion-item {
      margin-bottom: 20px;
      border: 3px solid #000;
      box-shadow: 18px 9px #000;
      position: relative;

      @media only screen and (max-width: 900px) {
        box-shadow: 9px 10px #000;
        margin-bottom: 14px;
      }

      &:before {
        content: '';
        display: block;
        height: 0;
        background: none;
        position: absolute;
        top: -3px;
        right: -22px;
        border-top: 10px solid transparent;
        border-bottom: 0 solid transparent;
        border-left: 20px solid #000;
        z-index: 1;
        width: 0;
        @media only screen and (max-width: 900px) {
          right: -12px;
          border-left: 10px solid #000;
        }
      }

      &:after {
        content: '';
        display: block;
        height: 0;
        background: none;
        position: absolute;
        bottom: -12px;
        left: -4px;
        border-top: 0px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 20px solid #000;
        z-index: 1;
        width: 0;

        @media only screen and (max-width: 900px) {
          left: -3px;
          border-right: 9px solid #000;
        }
      }
    }

    .accordion-button {
      box-sizing: border-box;
      border: 3px solid #000000;
      background: #f8d748;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #000;
      padding: 12px 50px 12px 29px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      overflow-wrap: break-word;
      cursor: url(../../images/cursor.svg), auto;

      @media only screen and (max-width: 900px) {
        font-size: 16px;
        line-height: 20px;
        padding: 12px 50px 12px 17px;
      }

      span.arrow {
        transform: rotate(180deg);
      }
    }

    .accordion-button.collapsed {
      span.arrow {
        transform: rotate(0deg);
      }
    }

    span.arrow {
      background: url(../../images/arrow.png);
      background-repeat: no-repeat;
      width: 14px;
      height: 9px;
      display: block;
      position: absolute;
      right: 26px;
      transition: 0.2s ease-in-out;

      @media only screen and (max-width: 900px) {
        right: 19px;
      }
    }

    .accordion-collapse {
      transition: height 0.35s ease;

      &:not(.show) {
        height: 0;
        overflow: hidden;
      }

      // &.collapsing {
      //   height: 0;
      //   overflow: hidden;
      // }

      // &.collapse {
      //   .accordion-body {
      //     display: none;
      //   }

      //   &.show {
      //     margin-bottom: 20px;
      //     border-right: 10px solid black;
      //     box-shadow: 5px 10px #000000;
      //     border-left: 2px solid black;
      //     .accordion-body {
      //       border: 10px solid #f8d748;
      //       display: block;
      //     }
      //   }
      // }
    }

    .answers {
      box-sizing: border-box;
      border: 3px solid #000000;
      background: #ffafff;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      padding: 15px 29px 15px 29px;
      overflow-wrap: break-word;

      @media only screen and (max-width: 900px) {
        font-size: 14px;
        line-height: 20px;
      }

      @media only screen and (max-width: 500px) {
        font-size: 12px;
        line-height: 18px;
      }

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .accordion-body {
    background: #f8d748;
    padding: 7px 10px 7px 7px;
    border-top: 2px solid #000;
  }

  .helpbtn {
    position: absolute;
    bottom: 20px;
    bottom: 50px;
  }

  .legal {
    position: relative;
    bottom: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 32px;
    color: #fff;
    left: 0;
    right: 0;
    text-align: center;
    cursor: url(../../images/cursor.svg), auto;
    display: flex;
    flex-wrap: wrap;

    justify-content: center;

    @media only screen and (max-width: 900px) {
      font-weight: 600;
      line-height: 20px;
    }

    .btn-link {
      font-weight: 700;
      font-size: 14px;
      line-height: 32px;
      box-shadow: none;
      text-decoration: underline;

      @media only screen and (max-width: 900px) {
        font-weight: 600;
        line-height: 20px;
      }
    }

    .animoca {
      margin-bottom: 20px;
      height: auto;
      width: 100px;
    }
  }

  .legal-icon {
    max-width: 80px;
    margin: 0 auto;
    width: 100%;
    @media only screen and (max-width: 900px) {
      margin: 0 auto 18px;
    }

    img {
      width: 79px;
    }
  }
}

.mocana-countdown-wrapper {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  max-width: 1228px;
  margin: auto;
  .content-header-left {
    &-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      color: #ffffff;
      margin-bottom: 12px;
    }
    &-description {
      font-size: 16px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 0;

      span.big-text {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #ffec00;
      }
      span.double-text {
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        color: #490505;
        background-image: url(../../images/mocana-header/double-bg.svg);
        background-size: contain;
        background-repeat: no-repeat;
        padding: 1px 19px;
      }
    }
  }
  .content-header-right {
    width: 100%;
    max-width: 477px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #adf0ff;
      text-align: end;
      margin-bottom: 3px;
    }
    &-content {
      padding: 24px;
      background-image: url(../../images/mocana-header/bg-countdown.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-countdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-title {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 0;
        }
        &-time {
          font-weight: 700;
          font-size: 28px;
          line-height: 34px;
          color: #ffcf00;
          margin-bottom: 0;
          display: flex;
          > img {
            margin-right: 5px;
          }
        }
      }
      &.hide-countdown {
        padding: 36px 32px;
        p {
          font-weight: 700;
          color: #ffffff;
        }
        .content-header-right-content-time-time {
          font-size: 1.1rem;
          color: #ffcf03;
        }
      }
      &-time {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title,
        &-time {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.7);
          margin-bottom: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .mocana-countdown-wrapper {
    .content-header-right {
      width: 100%;
      max-width: 450px;
    }
  }
}
@media only screen and (max-width: 991px) {
  .mocana-countdown-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 410px;
    padding: 0;
    .content-header-left {
      &-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 4px;
      }
      &-description {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        opacity: 0.8;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > span {
          margin-bottom: 4px;
          > br {
            display: none;
          }
        }
        span.big-text {
          font-weight: 800;
          font-size: 16px;
          line-height: 20px;
          color: #ffde00;
        }
      }
    }
    .content-header-right {
      margin-top: 29px;
      &-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        text-align: center;
        margin-bottom: -5px;
      }
      &-content {
        padding: 18.5px;
        &.hide-countdown {
          padding: 31.5px 22px;
          margin-top: 6px;
          width: auto;
          .content-header-right-content-time-time {
            font-size: 14.5px;
          }
        }
        &-countdown {
          &-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }
          &-time {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #ffcf00;
            > img {
              max-width: 23px;
            }
          }
        }
        &-time {
          &-title,
          &-time {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }
  }
}
